import React from 'react';

const PrivacyPolicy = () => {
    return (
        <section className="bg-gray-100/50 py-8">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">

                <div class="container mx-auto px-4">
                    <h1 class="text-xl font-bold text-center my-5">プライバシーポリシー</h1>

                    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h2 class="text-lg font-semibold mb-4">1. はじめに</h2>
                        <p class="mb-4">本プライバシーポリシーは、NEWTEQが提供するオンラインプログラミングスクールにおける個人情報の取り扱いについて説明しています。当スクールを利用するすべてのユーザーは、本ポリシーの内容を理解し、同意したものとみなします。</p>
                        
                        <h2 class="text-lg font-semibold mb-4">2. 収集する情報</h2>
                        <p class="mb-4">当スクールでは、以下の情報を収集することがあります：</p>
                        <p class="mb-4">
                            - 個人情報：氏名、住所、電話番号、メールアドレスなど<br/>
                            - 利用情報：アクセスログ、利用履歴、興味関心情報など<br/>
                            - 支払情報：クレジットカード情報、銀行口座情報など（決済代行サービスを通じて）
                        </p>

                        <h2 class="text-lg font-semibold mb-4">3. 情報の利用目的</h2>
                        <p class="mb-4">収集した情報は、以下の目的で利用されます：</p>
                        <p class="mb-4">
                            - 当スクールのサービス提供および運営<br/>
                            - ユーザーサポートおよび問い合わせ対応<br/>
                            - 新サービスやキャンペーンの案内<br/>
                            - サービス改善のための分析および調査
                        </p>

                        <h2 class="text-lg font-semibold mb-4">4. 情報の共有と開示</h2>
                        <p class="mb-4">当スクールは、以下の場合を除き、ユーザーの個人情報を第三者に開示または共有しません：</p>
                        <p class="mb-4">
                            - ユーザーの同意がある場合<br/>
                            - 法令に基づく場合<br/>
                            - ユーザーの権利や安全を守るため必要な場合<br/>
                        </p>

                        <h2 class="text-lg font-semibold mb-4">6. プライバシーポリシーの変更</h2>
                        <p class="mb-4">当スクールは、必要に応じて本ポリシーを更新することがあります。変更があった場合は、当スクールのウェブサイト上で通知します。</p>
                        <p class="mb-4">
                        </p>


                        <p class="text-end">最終改定日： 2023-11-14</p>
                    </div>

                    <p class="text-center mt-5">© NEWTEQ - All Rights Reserved</p>
                </div>

            </div>
        </section>
    );
};

export default PrivacyPolicy;
