import './App.css';
import Header from './components/Header';
import About from './components/About';
import Courses from './components/Courses';
import Contact from './components/Contact';
import Footer from './components/Footer';

import RepaymentGuarantee from './components/RepaymentGuarantee';
import CounselingRequest from './components/CounselingRequest';

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/AdminLogin';
import Admin from './components/Admin';

import AfterCounselingRequest from './components/AfterCounselingRequest';

import AiSpecielized from './components/AiSpecielized';
import OperationFriendship from './components/OperationFriendship';
import SuperHard from './components/SuperHard';

import Point from './components/Point';

import PrivacyPolicy from './components/PrivacyPolicy';
import SpecifiedCommercialTransaction from './components/SpecifiedCommercialTransaction'
import SalesMessage from './components/SalesMessage'
import Curriculum from './components/Curriculum'

import LoadmapAiSpecielized from './components/LoadmapAiSpecielized'
import LoadmapOperationFriendship from './components/LoadmapOperationFriendship'
import LoadmapSuperHard from './components/LoadmapSuperHard'




function App() {
    const [token, setToken] = useState(null);

    const handleLogin = (token) => {
        setToken(token);
    };
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <>
                        <Header />
                        <About />
                        <Point />
                        <Curriculum />
                        <Courses />
                        <SalesMessage />
                        <RepaymentGuarantee />
                        <CounselingRequest />
                        <Footer />
                    </>
                } />
                <Route path="/login" element={token ? <Navigate to="/admin" /> : <LoginForm onLogin={handleLogin} />} />
                <Route path="/admin" element={token ? <Admin /> : <Navigate to="/login" />} />
                <Route path="/after_counseling_request" element={<AfterCounselingRequest />} />
                <Route path="/contact" element={
                    <>
                        <Header />
                        <Contact />
                        <Footer />
                    </>
                } />
                <Route path="/privacy_policy" element={
                    <>
                        <Header />
                        <PrivacyPolicy />
                        <Footer />
                    </>
                } />
                <Route path="/specified_commercial_transaction" element={
                    <>
                        <Header />
                        <SpecifiedCommercialTransaction />
                        <Footer />
                    </>
                } />
                <Route path="/ai_specielized" element={
                    <>
                        <Header />
                        <AiSpecielized />
                        <Curriculum />
                        <LoadmapAiSpecielized />
                        <SalesMessage />
                        <RepaymentGuarantee />
                        <CounselingRequest />
                        <Footer />
                    </>
                } />

                <Route path="/operation_friendship" element={
                    <>
                        <Header />
                        <OperationFriendship />
                        <Curriculum />
                        <LoadmapOperationFriendship />
                        <SalesMessage />
                        <RepaymentGuarantee />
                        <CounselingRequest />
                        <Footer />
                    </>
                } />
                <Route path="/super_hard" element={
                    <>
                        <Header />
                        <SuperHard />
                        <Curriculum />
                        <LoadmapSuperHard />
                        <SalesMessage />
                        <RepaymentGuarantee />
                        <CounselingRequest />
                        <Footer />
                    </>
                } />
            </Routes>
        </Router>
    );
}

export default App;
