import React from 'react';

const IconText = ({ children }) => (
  <div className="flex items-center justify-center mb-4">
    <span className="mr-2 text-green-500 font-bold text-xl">✓</span> {/* アイコンをチェックマークに変更 */}
    <p className="text-gray-700 font-bold text-xl">{children}</p>
  </div>
);

const RepaymentGuarantee = () => {
    return (
        <div className="bg-blue-100/50 p-6 rounded-lg shadow-lg"> {/* 背景色を淡い青に変更 */}
            <h2 className="text-2xl font-bold mb-5 text-blue-800 flex items-center justify-center">安心の返金保証</h2>
            <IconText>プランが合わなくても大丈夫！</IconText>
            <IconText>7日間返金保証*¹</IconText>
            <p className="text-sm text-gray-600 flex items-center justify-center mb-4">
                ※¹ 契約開始より7日以内の申請で、受講のキャンセルを無条件で行うことができます。返金保証の詳細は申込規約をご覧ください。
            </p>
        </div>
    );
};
export default RepaymentGuarantee;