import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AfterCounselingRequest = () => {
    const [isValidToken, setIsValidToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    const [formData, setFormData] = useState({
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        phoneNumber: '',
        birthDate: '',
        prefecture: '',
        currentJob: '',
        employmentType: '',
        yearsOfService: '',
        learningObjective: '',
        interestedCourse: '',
        futureCareer: '',
        startPeriod: '',
        howDidYouKnow: '',
        counselingQuery: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            verifyToken(token);
        } else {
            setIsLoading(false);
        }
    }, [location]);

    const verifyToken = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/verifyToken?token=${token}`);
            if (response.ok) {
                setIsValidToken(true);
            } else {
                setIsValidToken(false);
            }
        } catch (error) {
            console.error('トークン検証中にエラーが発生しました:', error);
            setIsValidToken(false);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isValidToken) {
        return <div>無効なトークンです。アクセスできません。</div>;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const counselingRequestId = params.get('counseling_request_id');
        if (!token) {
            alert('トークンが見つかりません。再度試してください。');
            return;
        }
        if (!formData.lastName || !formData.firstName) {
            alert('必須フィールドをすべて入力してください。');
            return;
        }
        try {
            const verifyResponse = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/verifyToken?token=${token}`);
            if (!verifyResponse.ok) {
                alert('無効なトークンです。アクセスできません。');
                return;
            }

            // トークンが有効な場合のみフォーム送信処理を続行
            formData.counseling_request_id = counselingRequestId;
            const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/counselingRequestDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            alert('データが正常に保存されました。\n ホーム画面に遷移します。');
            navigate('/');

        } catch (error) {
            console.error('エラーが発生しました:', error);
            alert('データの保存に失敗しました。');
        }
    };

    return (
        <div className="container mx-auto">
            <div className="top relative">
                <p className="bg-gray-200/50 p-3 rounded absolute top-[50%] left-[13%] font-bold text-2xl text-gray-700">NEWTEQ無料カウンセリングにお申込みいただきありがとうございます</p>
            </div>
            <div className="px-16 py-3 text-center">
                <h2 className="my-5 text-4xl font-bold">カウンセリング事前アンケート</h2>
                <p className="">
                    カウンセリングをスムーズに進めるために以下のアンケートにご協力ください。<br/>
                    回答いただいた内容を基にカウンセリングを行います。<br/>
                    現時点でご質問などある場合は最終項目の「カウンセリングで知りたいこと」の欄にご記入ください。
                </p>
            </div>
            <form onSubmit={handleSubmit} className="px-16 py-5">
                {/* 性 */}
                <div className="mb-4">
                    <label htmlFor="lastName" className="block text-gray-700 text-sm font-bold mb-2">性*</label>
                    <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 名 */}
                <div className="mb-4">
                    <label htmlFor="firstName" className="block text-gray-700 text-sm font-bold mb-2">名*</label>
                    <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* セイ */}
                <div className="mb-4">
                    <label htmlFor="lastNameKana" className="block text-gray-700 text-sm font-bold mb-2">セイ*</label>
                    <input type="text" id="lastNameKana" name="lastNameKana" value={formData.lastNameKana} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* メイ */}
                <div className="mb-4">
                    <label htmlFor="firstNameKana" className="block text-gray-700 text-sm font-bold mb-2">メイ*</label>
                    <input type="text" id="firstNameKana" name="firstNameKana" value={formData.firstNameKana} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 電話番号 */}
                <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">電話番号（ハイフンなし）*</label>
                    <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 生年月日 */}
                <div className="mb-4">
                    <label htmlFor="birthDate" className="block text-gray-700 text-sm font-bold mb-2">生年月日*</label>
                    <input type="date" id="birthDate" name="birthDate" value={formData.birthDate} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 都道府県 */}
                <div className="mb-4">
                    <label htmlFor="prefecture" className="block text-gray-700 text-sm font-bold mb-2">都道府県*</label>
                    <input type="text" id="prefecture" name="prefecture" value={formData.prefecture} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 現職 */}
                <div className="mb-4">
                    <label htmlFor="currentJob" className="block text-gray-700 text-sm font-bold mb-2">現職*</label>
                    <input type="text" id="currentJob" name="currentJob" value={formData.currentJob} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* 現職の雇用形態 */}
                <div className="mb-4">
                    <label htmlFor="employmentType" className="block text-gray-700 text-sm font-bold mb-2">現職の雇用形態*</label>
                    <select id="employmentType" name="employmentType" value={formData.employmentType} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        <option value="">選択してください</option>
                        <option value="正社員">正社員</option>
                        <option value="契約社員">契約社員</option>
                        <option value="派遣社員">派遣社員</option>
                        <option value="パート・アルバイト">パート・アルバイト</option>
                        <option value="その他">その他</option>
                    </select>
                </div>

                {/* 現職の勤続年数 */}
                <div className="mb-4">
                    <label htmlFor="yearsOfService" className="block text-gray-700 text-sm font-bold mb-2">現職の勤続年数*</label>
                    <input id="yearsOfService" type="number" name="yearsOfService" value={formData.yearsOfService} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>

                {/* プログラミング学習の目的 */}
                <div className="mb-4">
                    <label htmlFor="learningObjective" className="block text-gray-700 text-sm font-bold mb-2">プログラミング学習の目的*</label>
                    <textarea id="learningObjective" name="learningObjective" value={formData.learningObjective} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
                </div>

                {/* 興味をお持ちのコース */}
                <div className="mb-4">
                <label htmlFor="interestedCourse" className="block text-gray-700 text-sm font-bold mb-2">興味をお持ちのコース*</label>
                <select id="interestedCourse" name="interestedCourse" value={formData.interestedCourse} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option value="">選択してください</option>
                    <option value="初級コース">AI特化コース</option>
                    <option value="中級コース">仲良し大作戦コース</option>
                    <option value="上級コース">超ハードコース</option>
                </select>
                </div>

                {/* 今後の転職・就職について */}
                <div className="mb-4">
                <label htmlFor="futureCareer" className="block text-gray-700 text-sm font-bold mb-2">今後の転職・就職について*</label>
                <textarea id="futureCareer" name="futureCareer" value={formData.futureCareer} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
                </div>

                {/* 仮に受講が決定した際どのくらいで開始するか */}
                <div className="mb-4">
                <label htmlFor="startPeriod" className="block text-gray-700 text-sm font-bold mb-2">仮に受講が決定した際どのくらいで開始したいか*</label>
                <select id="startPeriod" name="startPeriod" value={formData.startPeriod} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option value="">選択してください</option>
                    <option value="すぐに開始">すぐに開始</option>
                    <option value="1週間以内に開始">1週間以内に開始</option>
                    <option value="1ヶ月以内に開始">1ヶ月以内に開始</option>
                    <option value="3ヶ月以内に開始">3ヶ月以内に開始</option>
                    <option value="それ以上">それ以上</option>
                </select>
                </div>

                {/* NEWTEQを知ったきっかけ */}
                <div className="mb-4">
                    <label htmlFor="howDidYouKnow" className="block text-gray-700 text-sm font-bold mb-2">NEWTEQを知ったきっかけ*</label>
                    <select id="howDidYouKnow" name="howDidYouKnow" value={formData.howDidYouKnow} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        <option value="">選択してください</option>
                        <option value="インターネット検索">インターネット検索</option>
                        <option value="SNS">SNS</option>
                        <option value="友人・知人の紹介">友人・知人の紹介</option>
                        <option value="その他">その他</option>
                    </select>
                </div>

                {/* カウンセリングで知りたいこと */}
                <div className="mb-4">
                    <label htmlFor="counselingQuery" className="block text-gray-700 text-sm font-bold mb-2">カウンセリングで知りたいこと*</label>
                    <textarea id="counselingQuery" name="counselingQuery" value={formData.counselingQuery} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
                </div>

                {/* 運営からの注意書き */}
                {/* <div className="mb-4">
                    <p className="text-gray-600 text-sm">※運営からの注意書き: ここに注意書きの内容を記載します。*</p>
                </div> */}

                {/* 送信ボタン */}
                <div className="flex items-center justify-between">
                    <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-5 px-20 rounded focus:outline-none focus:shadow-outline" type="submit">
                    送信
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AfterCounselingRequest;
