import React from 'react';

const SalesMessage = () => {
    return (
        <div className="sales-message">
            <div className="text-center mb-5 py-20 bg-green-100/50">
                <div className=" text-gray-600 max-w-[90%] mx-auto">
                    <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">求められるエンジニア</h1>
                    <p className="text-gray-600 mb-5">
                        AIによるノーコード・コード補完は日々成長しています。<br/>
                        <span className="text-red-400/80"> コードを書く能力 </span>
                        <span className="font-bold text-lg"> &lt;&lt;&lt; </span>
                        <span className="text-red-400 font-bold text-xl"> AIを使いこなす能力</span>
                    </p>
                    <p className="">
                        何が必要でどうなりたいのか、「ビジョン」を共に見据え、<span className="font-bold">NEWTEQ</span> と駆け上がりましょう！
                    </p>
                </div>
            </div>
            <div className="bg-gray-100/10 text-center py-20 max-w-[90%] mx-auto">
                <h1 className="text-3xl font-bold mb-3">「選んでよかった」を実現する3つのサポート</h1>
                <p className="text-gray-500 text-base">あなたの成長を３方向からサポートし、「NEWTEQを選んでよかった」と言わせてみせます。</p>
                <img src={`${process.env.PUBLIC_URL}images/support.jpg`} className="w-full" alt=""></img>
            </div>
        </div>
        );
    };

export default SalesMessage;
