import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


const Popup = ({ show, onClose }) => {
    if (!show) {
      return null;
    }

    const handleClose = () => {
        onClose(); // まずポップアップを閉じる
        window.location.reload(); // その後ページをリロードする
      };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4 py-6">
        <div className="bg-white rounded-xl shadow-lg transform transition-all scale-95 hover:scale-100">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-gray-600 mb-4">予約が完了しました！</h2>
            <p className="text-gray-600">確認メールを送信しましたのでご確認ください。</p>
            <button
              className="mt-4 py-2 px-4 text-4xl bg-green-100 hover:bg-green-200 font-semibold rounded transition-colors"
              onClick={handleClose}
            >
              ✔
            </button>
          </div>
        </div>
      </div>
    );
};

const CounselingRequest = () => {
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [agreed, setAgreed] = useState(false);

    const timeSlots = Array.from({ length: 11 }, (_, index) => `${index + 10}:00`);

    const initialSchedule = timeSlots.reduce((schedule, timeSlot) => {
        schedule[timeSlot] = Array(7).fill(false); // 7日分の予約可能状態をfalse（予約不可）で初期化
        return schedule;
    }, {});

    // const today = new Date();
    const today = useMemo(() => new Date(), []);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // ここで翌日に設定

    // 1週間分の日付を生成（本日を除く）
    const dates = Array.from({ length: 7 }, (_, i) => {
        const date = new Date(tomorrow);
      date.setDate(tomorrow.getDate() + i); // ここで翌日からの日付を生成
        return date;
    });

    // 予約状況を表すダミーデータ
    const [schedule, setSchedule] = useState(initialSchedule);


    useEffect(() => {
    }, [schedule]);

      // コンポーネントがマウントされた時にスケジュールを取得
    // useEffect(() => {
    //     fetchSchedule();
    // }, []);

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/schedule`);
                if (response.ok) {
                    const data = await response.json();
    
                    setSchedule(prevSchedule => {
                        const newSchedule = { ...prevSchedule };
                        for (const [dateString, times] of Object.entries(data)) {
                            const date = new Date(dateString + 'T00:00:00+09:00');
    
                            // 今日の日付を基準にdayIndexを計算
                            const dayIndex = Math.round((date - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));
    
                            // dayIndexが1以上（つまり明日以降）の場合のみ更新
                            if (dayIndex >= 1 && dayIndex <= 7) {
                                for (const [time, available] of Object.entries(times)) {
                                    if (!newSchedule[time]) {
                                        newSchedule[time] = Array(7).fill(false);
                                    }
                                    newSchedule[time][dayIndex - 1] = available; // dayIndexを1減らしてインデックスとして使用
                                }
                            }
                        }
                        return newSchedule;
                    });
                }
            } catch (error) {
                console.error('スケジュールの取得中にエラーが発生しました:', error);
            }
        };
        fetchSchedule();
    }, [today]);

    const handleTimeSlotClick = (time, dayIndex) => {
        const dateTime = `${dates[dayIndex].toLocaleDateString()} ${time}`;
        setSelectedDateTime(dateTime);
    };

    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (agreed && selectedDateTime && name && email && phone) {
            const formData = { selectedDateTime, name, email, phone };
            try {
                const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/counselingRequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    const data = await response.json(); // レスポンスのJSONを解析
                    const { redirectUrl } = data;
                    alert('データが正常に送信されました。\n カウンセリングをスムーズに行うために、これから遷移する画面でいくつかの質問に回答のご協力をお願いします。');
                    navigate(redirectUrl);
                } else {
                    const errorData = await response.json();
                    console.error('サーバーエラー:', errorData.error);
                    alert(`エラー: ${errorData.error}`);
                }
            } catch (error) {
                // ネットワークエラーなどのエラーハンドリング
                console.error('送信エラー:', error);
                alert('送信中にエラーが発生しました。'); // エラーメッセージをアラートで表示
            }

        } else {
            alert('全ての項目を入力し、プライバシーポリシーに同意してください。');
        }
    };

    return (
        <div className="max-w-[90%] mx-auto py-20">
            <div className="mb-10">
                <h2 id="counseling" className="text-2xl md:text-3xl font-bold mb-8 text-center text-gray-100 w-full py-8 bg-blue-900">無料カウンセリングにお任せ</h2>
                <img className="inline w-1/2 h-full" src={`${process.env.PUBLIC_URL}/images/counselingGirl.png`} alt=""></img>
                <img className="inline w-1/2 h-full" src={`${process.env.PUBLIC_URL}/images/counselingMan.png`} alt=""></img>
                <div className=" bg-white text-center text-gray-600">
                    <p className="mb-5 mt-5">
                        エンジニアスタッフとの無料カウンセリングで不安を解消し最適な学習環境を。
                    </p>
                    <p className="mb-5">
                        カウンセリングは約45分です
                    </p>
                    <p className="mb-5">
                        事前アンケートを基に進めつつ、スクールの説明・質疑応答を「トコトン」行います。
                    </p>
                    <p className="">
                        不必要な不安はここで吹き飛ばして学習に臨みましょう！
                    </p>
                </div>
            </div>
            <h2 className="text-2xl font-bold mb-1 text-center">カウンセリング希望日をお選びください</h2>
            <p className="mb-5 text-center text-sm text-gray-500">
                *当カウンセリングはお問い合わせ用ではありません。お問い合わせは<a href="contact" target="_brank" className="text-blue-500"> こちら </a>よりお願いします。
            </p>
            <div className="mb-4">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="border-b border-gray-100">
                            <th className="border-r border-gray-100">日時</th>
                            {dates.map((date, index) => (
                                <th key={index} className="border-r border-gray-100">
                                    <span>
                                    {date.toLocaleDateString('ja-JP', {
                                        month: 'numeric',
                                        day: 'numeric'
                                    })}
                                    </span>
                                    <span style={{ display: 'block' }}>
                                    {date.toLocaleDateString('ja-JP', {
                                        weekday: 'short'
                                    })}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 11 }, (_, i) => 10 + i).map(hour => (
                            <tr key={hour}>
                            <td className="border-r border-b border-gray-100">{`${hour}:00`}</td>
                            {dates.map((date, dayIndex) => (
                                <td
                                key={dayIndex}
                                className={`border-r border-b border-gray-100 md:p-5 text-center ${schedule[`${hour}:00`][dayIndex] ? 'text-green-300 hover:bg-green-100 cursor-pointer' : 'bg-gray-200'}`}
                                onClick={() => schedule[`${hour}:00`][dayIndex] && handleTimeSlotClick(`${hour}:00`, dayIndex)}
                                >
                                <span className={`font-bold text-4xl ${schedule[`${hour}:00`][dayIndex] ? 'text-green-500' : ''}`}>{schedule[`${hour}:00`][dayIndex] ? '○' : '×'}</span>
                            </td>
                            ))}
                        </tr>
                        ))}
                    </tbody>
                </table>
                <p className="bg-yellow-100 p-10 font-bold text-2xl text-center">選択中の日時 <br/>{selectedDateTime}</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">氏名（カタカナ）</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                />
                </div>
                <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">メールアドレス</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                />
                </div>
                <div className="mb-4">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">電話番号</label>
                <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                />
                </div>
                <div className="mb-4">
                <label className="inline-flex items-center">
                    <input
                    type="checkbox"
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}
                    required
                    className="w-6 h-6 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <span className="ml-2 text-gray-600">プライバシーポリシー <a href="privacy_policy" target="_blank" className=" dark:text-gray-400 underline"> ↗</a> を確認し同意</span>
                </label>
                </div>
                <button type="submit" className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700">送信</button>
            </form>
            <Popup show={showPopup} onClose={() => setShowPopup(false)} />
        </div>
    );
};

export default CounselingRequest;
