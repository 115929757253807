import React from 'react';

const Point = () => {
    return (
        <section className="bg-gray-100/50 py-8">
            <div className="container mx-auto text-center px-8 md:px-12 lg:px-20">
                <div className="flex flex-wrap justify-center ">
                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
                        <div className="h-full px-3 py-6 rounded-lg border-2 border-yellow-300/70 flex flex-col justify-between bg-white shadow-sm">
                            <h3 className="text-lg font-bold mb-4">革新的なカリキュラム</h3>
                            <p className="text-gray-600 mb-3">
                                毎週、１週間分のカリキュラムを現役エンジニアと組みます。
                            </p>
                            <p className="text-gray-600 mb-3">
                                あなたに合った<span className="font-bold text-gray-500">あなただけのカリキュラム。</span>
                            </p>
                            <p className="text-gray-600 mb-3">
                                短いスパンで驚異的な成長を可能にします。
                            </p>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
                        <div className="h-full px-3 py-6 rounded-lg border-2 border-yellow-300/70 flex flex-col justify-between bg-white shadow-sm">
                            <h3 className="text-lg font-bold mb-4">経験豊富な講師陣</h3>
                            <p className="text-gray-600 mb-3">
                                画像生成や最新技術を駆使するエンジニアスタッフたち。
                            </p>
                            <p className="text-gray-600 mb-3">
                                彼らと触れ合う中で業界を<span className="font-bold text-gray-500">肌で感じる。</span>
                            </p>
                            <p className="text-gray-600 mb-3">
                                教えることが大好きで、学習外の質問にも喜んで答えてくれます。
                            </p>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
                        <div className="h-full px-3 py-6 rounded-lg border-2 border-yellow-300/70 flex flex-col justify-between bg-white shadow-sms">
                            <h3 className="text-lg font-bold mb-4">技術スキルだけじゃない</h3>
                            <p className="text-gray-600 mb-3">
                                いくら技術を磨いても発揮できなければ意味がない。
                            </p>
                            <p className="text-gray-500 mb-3 font-bold">
                                プログラミング力 &lt;&lt;&lt; 主体性
                            </p>
                            <p className="text-gray-600 mb-3">
                                フリーランス出身の私たちが学習と並行で主体性も鍛え上げます。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Point;
