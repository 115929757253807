import React from 'react';

const AiSpecielized = () => {
    return (
        <section className="">
            <div className="bg-gray-100/10">
                <img src={`${process.env.PUBLIC_URL}/images/ai_specialized.png`} className="flex justify-center w-full relative" alt=""></img>
                <div className="container mx-auto px-8 md:px-12 lg:px-20">
                    <div className="container mx-auto px-4">
                        <h1 className="text-3xl font-bold text-center text-gray-800 my-6">AI特化コース</h1>
                        <div className="mx-auto py-5 text-center bg-white/50 rounded-lg relative font-bold text-red-500 mb-8">
                            <p className="text-center text-4xl md:text-5xl">98,000
                                <div className="inline absolute">
                                    <span className="text-xl">&ensp;円&nbsp;</span><span className="text-base">税込</span>
                                </div>
                            </p>
                        </div>
                        <p className="text-gray-600 text-lg pb-10 text-center">
                            <br/>
                            AIの基礎から応用までを経験し、ChatGPT含む最先端のAIを使いこなすスキルを身につけます。<br/>
                            AIの可能性を最大限に活用し、あなたのキャリアやプロジェクトに革新をもたらすための第一歩となります。<br/>
                            実際にAIを組み込んだアプリを開発するので、楽しみにしていてください。
                        </p>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default AiSpecielized;