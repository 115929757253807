import React from 'react';

const SpecifiedCommercialTransaction = () => {
    return (
        <section className="bg-gray-100/50 py-8">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                <div class="container mx-auto px-4 py-6">
                    <h2 class="text-xl font-bold mb-4">特定商取引法に基づく表記</h2>

                    <div class="mb-4">
                        <h3 class="font-semibold">事業者名</h3>
                        <p>NEWTEQ</p>
                    </div>

                    <div class="mb-4">
                        <h3 class="font-semibold">メールアドレス</h3>
                        <p>newteq@newteq.com</p>
                    </div>

                    <div class="mb-4">
                        <h3 class="font-semibold">お支払方法</h3>
                        <p>銀行振込</p>
                    </div>

                    <div class="mb-4">
                        <h3 class="font-semibold">サービス提供開始時期</h3>
                        <p>ご入金確認後、受講日程の開始日よりサービス提供を開始いたします。</p>
                    </div>

                    <div class="mb-4">
                        <h3 class="font-semibold">返金について</h3>
                        <p>サービス提供開始から7日以内の申請で受講のキャンセルを行うことができます。</p>
                    </div>


                </div>
            </div>
        </section>
    );
};

export default SpecifiedCommercialTransaction;
