import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-6 py-4 text-xs">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 text-center md:text-left">
            <a className="text-lg font-bold" href="/">NEWTEQ</a>
            <p className="text-gray-400">© 2023 NEWTEQ. All rights reserved.</p>
          </div>
          <div className="w-full md:w-1/3 text-center md:text-right">
            <ul className="inline-flex mt-4 md:mt-0">
              <li><a href="privacy_policy" className="hover:text-gray-300">プライバシーポリシー</a></li>
              <li className="ml-4"><a href="specified_commercial_transaction" className="hover:text-gray-300">特定商取引法に基づく表記</a></li>
              <li className="ml-4"><a href="contact" className="hover:text-gray-300">お問い合わせ</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
