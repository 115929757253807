import React from 'react';

const LoadmapSuperHard = () => {
    return (
        <section className="pt-10 pb-20 max-w-[90%] mx-auto">
            <h2 className="text-center block text-4xl font-bold text-gray-800 mb-4">ロードマップ</h2>
            <p className="text-gray-600 mb-5 text-center">
                特製カリキュラムにより爆速成長を可能に。
            </p>
            <p className="text-gray-600 mb-5 text-center">
                ビジョンを見据えて駆け上がりましょう！
            </p>
            <div className="text-center font-bold text-lg justify-center flex">
                <img className="sm:h-[500px] md:h-[750px]" src={`${process.env.PUBLIC_URL}/images/Loadmap_superHard.png`} alt=""></img>
            </div>
            <p className="text-gray-600 mb-5 text-center">
                ロードマップに従う必要はありません。
            </p>
            <p className="text-gray-600 mb-5 text-center">
                今後運用をする予定が無ければ今学ぶ必要もありません。必要のない箇所を飛ばして大事なところにフォーカスできます。
            </p>
        </section>
    );
};

export default LoadmapSuperHard;
