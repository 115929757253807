import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef();

    // メニュー外のクリックを検出してメニューを閉じる
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <nav className="bg-gray-900/80 text-white p-2">
            <div className="container mx-auto px-6 py-3 flex justify-between items-center">
                <a className="text-white text-3xl font-bold" href="/">NEWTEQ</a>
                <div className="flex space-x-6 z-10">
                    <div className="contact" >
                        <a className="hover:text-gray-300 text-2xl !mr-2" href="contact">
                            <FontAwesomeIcon icon={faCommentDots} />
                        </a>
                    </div>
                    <div className="flex items-center" ref={menuRef}>
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>コース一覧</button>
                        {isMenuOpen && (
                            <div className="absolute top-[66px] right-0 mt-2 py-2 w-48 bg-gray-900 rounded-md shadow-xl">
                                <a href="ai_specielized" className="block px-4 py-2 text-sm font-bold hover:bg-gray-800 hover:text-green-400/90">AI特化コース</a>
                                <a href="operation_friendship" className="block px-4 py-2 text-sm font-bold hover:bg-gray-800 hover:text-green-400/90">仲良し大作戦コース</a>
                                <a href="super_hard" className="block px-4 py-2 text-sm font-bold hover:bg-gray-600 hover:text-green-400/90">超ハードコース</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
