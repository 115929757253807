import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const coursesData = [
    {
        id: 1,
        title: 'AI特化コース',
        description: '実際の開発を通してAIスキルをメインに磨きます。修学中にAIと共にアプリやサイトを作れる力が身に付きます。AIとのプログラミングを学びたい方に向けたコースです。',
        imageUrl: `${process.env.PUBLIC_URL}/images/ai_specialized.png`,
        level: '★★★',
        linkUrl: 'ai_specielized'
    },
    {
        id: 2,
        title: '仲良し大作戦コース',
        description: 'プログラムの基礎をメインに学びつつ、AIと共に簡単な開発も行います。プログラミングを好きになりたい方に向けたコースです。',
        imageUrl: `${process.env.PUBLIC_URL}/images/operation_friendship.png`,
        level: '★★',
        linkUrl: 'operation_friendship'
    },
    {
        id: 3,
        title: '超ハードコース',
        description: 'フロントエンドとバックエンドの両方のスキルを習得し、全方位的な開発者になります。修学後は転職活動やフリーランサーとして活躍したい猛者に向けたコースです。',
        imageUrl: `${process.env.PUBLIC_URL}/images/super_hard.png`,
        level: '★★★★★',
        linkUrl: 'super_hard'
    },
];

const CourseCard = ({ course }) => (
    <div className="bg-gray-100/10 h-full max-w-sm rounded overflow-hidden border border-yellow-300/50 shadow-lg">
            <img className="w-full h-[150px]" src={course.imageUrl} alt="" />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-center">{course.title} <span className="text-yellow-300/100 flex justify-end">{course.level}</span></div>
                <p className="text-gray-700 text-base">
                    {course.description}
                </p>
                <a href={course.linkUrl} className="flex  justify-center mt-5 w-full px-3 py-1 bg-white border rounded relative hover:scale-110 hover:border-gray-400">
                    詳細はこちら
                    <span className="absolute right-3 font-bold"><FontAwesomeIcon icon={faAngleRight} /></span>
                </a>
            </div>
    </div>
);

const Courses = () => {
  return (
    <section className="bg-gray-100/50 py-20">
      <div className="max-w-[90%] mx-auto">
        <div className="text-center max-w-xl mx-auto mb-12">
          <h2 className="text-3xl font-bold text-gray-800 mb-2">コース一覧</h2>
          <p className="text-gray-600">
            NEWTEQでは、さまざまなビジョンに合わせたコースを提供しています。
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          {coursesData.map(course => (
            <div key={course.id} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <CourseCard course={course} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
