import React from 'react';
import { Link } from 'react-scroll';

const About = () => {
    return (
        <section className="pt-14 pb-20 max-w-[90%] mx-auto">
            <style>
                {`
                    .bg-glitter {
                        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
                        background-size: 1rem 1rem;
                    }
                    .bg-glitter:hover {
                        background-position: 100% 100%;
                    }
                `}
            </style>
            <div className="text-center font-bold text-lg">
                <h2 className="text-5xl font-bold text-gray-800 mb-4">NEWTEQ</h2>
                <p className="text-gray-600 mb-3">
                    NEWTEQは、AIを駆使したプログラミングスキルを養えるオンラインのスクールです。
                </p>
                <p className="text-gray-600 mb-3">
                    実践的な学習と個別指導を通じて1ヵ月でつよつよエンジニアに。
                </p>
                <p className="text-gray-600 mb-3">
                    プログラミングやAIが大好きな講師たちによる挫折しない環境での楽しい学習期間を贈ります。
                </p>
                <p className="text-gray-600 mb-14">
                    無料カウンセリングで
                    <span className="text-red-400/90">
                        プログラミング頑張りたい！
                    </span>
                    という方をお待ちしております！
                </p>
                <Link to="counseling" smooth={true} duration={500} className="px-10 py-5 text-white rounded bg-blue-700/90 text-3xl font-bold bg-glitter hover:bg-blue-700 cursor-pointer">
                    無料カウンセリングを予約する！
                </Link>
                {/* <p className="text-gray-600 mb-3">
                    AIが求められる未来に向けて「今」ビジョンを明確に。
                </p>
                <p className="text-gray-600">
                    実践的な学習と個別指導を通じて、あなたのビジョンを次のレベルへと導きます。
                </p> */}
            </div>
        </section>
    );
};

export default About;
