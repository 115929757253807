import React from 'react';

const OperationFriendship = () => {
    return (
        <section className="">
            <div className="bg-gray-100/10">
                <img src={`${process.env.PUBLIC_URL}/images/operation_friendship.png`} className="flex justify-center w-full relative" alt=""></img>
                <div className="container mx-auto px-8 md:px-12 lg:px-20">
                    <div className="container mx-auto px-4">
                        <h1 className="text-3xl font-bold text-center text-gray-800 my-6">仲良し大作戦コース</h1>
                        <div className="mx-auto py-5 text-center bg-white/50 rounded-lg relative font-bold text-red-500 mb-8">
                            <p className="text-center text-4xl md:text-5xl">78,000
                                <div className="inline absolute">
                                    <span className="text-xl">&ensp;円&nbsp;</span><span className="text-base">税込</span>
                                </div>
                            </p>
                        </div>
                        <p className="text-gray-600 text-lg pb-10 text-center">
                            仲良し大作戦。なによりもプログラミングを楽しみ、好きだと感じてもらうためのコースです。<br/>
                            難解で不愛想なコードもよくみると可愛いところがあります。<br/>
                            簡単なアプリをAIと一緒に作り、「なんだこんな簡単なのか」と距離を縮めてもらいたいです。
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OperationFriendship;