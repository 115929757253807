import React, { useState, useEffect } from 'react';

const Admin = () => {
  const [reservations, setReservations] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const fetchReservations = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('トークンが存在しません。');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/admin/counselingRequests`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`データ取得エラー: ${response.status}`);
      }

      const data = await response.json();
    //   console.log('data: ', data)
      if (!Array.isArray(data)) {
        setReservations([data]);
      } else {
        setReservations(data);
      }
    } catch (error) {
      console.error('予約データ取得時のエラー:', error);
    }
  };

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };

    checkAuth();
  }, []);

  useEffect(() => {
    
    if (isLoggedIn) {
      fetchReservations();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <div>ログインが必要です。</div>;
  }

  function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${hours}:${minutes}`;
  }

  // 日時が近い順にソートする関数
  function sortByDateTime(reservations) {
    return reservations.sort((a, b) => {
      const dateA = new Date(a.date_time);
      const dateB = new Date(b.date_time);
      return dateA - dateB;
    });
  } 
  // レンダリング部分
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">管理者画面</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ID</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">日時</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">名前</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">メール</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">電話番号</th>
            </tr>
          </thead>
          <tbody>
            {sortByDateTime(reservations).map((reservation) => (
              <tr key={reservation.id}>
                <td className="py-2 px-4 border-b border-gray-200">{reservation.id}</td>
                <td className="py-2 px-4 border-b border-gray-200">{formatDateTime(reservation.date_time)}</td>
                <td className="py-2 px-4 border-b border-gray-200">{reservation.name}</td>
                <td className="py-2 px-4 border-b border-gray-200">{reservation.email}</td>
                <td className="py-2 px-4 border-b border-gray-200">{reservation.phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

};

export default Admin;