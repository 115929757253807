import React from 'react';

const Curriculum = () => {
    return (
        <section className="">
            <div className="bg-orange-100/80 py-20">
                <div className="max-w-[90%] mx-auto">
                    <div className="">
                        <h1 className="text-3xl font-bold text-center text-gray-800 my-6">オリジナルカリキュラム</h1>
                        <p className="text-gray-600 text-lg pb-5 text-center">
                            毎週、あなたとエンジニアスタッフで１週間分の<span className="font-bold">あなた</span>のカリキュラムを作成します。<br/>
                            学習進捗・今後の要望などを基に設計されるカリキュラムは、短いスパンでの驚異的な成長を可能にします。<br/>
                            最高のカリキュラムのために、あなたの要望を<span className="font-bold">ありったけ</span>聞かせてください！
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Curriculum;